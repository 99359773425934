import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { EmptyData } from "../../EmptyData";

export interface ChartBarProps {
  labels: Array<string>;
  values: Array<any>;
  width?: string | number;
  height?: string | number;
  tooltip?: boolean;
  name?: string;
}

export function ChartBar({ labels, values, width, height, name='', tooltip = false }: ChartBarProps) {
  const options: ApexOptions = {
    colors: ["#F49431"],
    labels,
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: tooltip,
    },
    xaxis: {
      axisBorder: {
        color: "#8B929E",
      },
      axisTicks: {
        color: "#8B929E",
      },

      categories: labels,
    },
    fill: {
      opacity: 1,
      colors: ["#F49431"],
      gradient: {
        shade: "dark",
        opacityFrom: 0.7,
        opacityTo: 0.6,
      },
    },
  };

  const series = [{ data: values, name }];

  if (!values.length) return <EmptyData />;
  
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height={height}
      width={width}
    />
  );
}

import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { Constant } from '../../shared/constants';

const http: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_PAINEL_BASE_URL}${Constant.API_URI}${process.env.REACT_APP_API_VERSION}`,
});

http.interceptors.request.use((config) => {
    const token = process.env.REACT_APP_AUTHORIZATION_TOKEN;

    if (token) config.headers['Authorization'] = token;
    return config;
});

http.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

export const get = async <T>(url: string, params?: any): Promise<T> => {
    try {
      const response = await http.get<T>(url, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
};

export const post = async <T>(url: string, data?: any): Promise<T> => {
    try {
        const response = await http.post<T>(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
export enum Constant {
  UNNAMED = "desconhecido",
  API_URI = "api/",
  IMOVEIS = 'imoveis',
  CONDOMINIOS = 'condominios',
}

export enum Tabs {
    VENDA = 1,
    ALUGUEL = 2,
    TEMPORADA = 3,
}

export const DATETIME_TYPE = 'datetime';
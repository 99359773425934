import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;

export const Titulo = styled.h1`
    color: #23476C;
    font-size: 22px;
    text-wrap: nowrap;
`;

export const Line = styled.div`
    height: 0.5px;
    width: 100%;
    background-color: gray;
    margin-top: 5px;
`;
export const Button = styled.a`
    all: unset;
    font-size: 16px;
    color: orange;
    margin-left: 15px;
    width: auto;
    font-weight: bold;
    text-wrap: nowrap;
    cursor: pointer;
`;

import { Imovel } from "../../../shared/types/imoveis.type";
import { Card, DivInfo, LineCard, Number, Text, TitleCard } from "./styles";

interface Props {
  emCondominio: Array<Imovel>;
  foraDeCondominio: Array<Imovel>;
}

export function CardImovel003({ emCondominio, foraDeCondominio }: Props) {

  return (
    <Card data-testid='cardimovel003'>
      <TitleCard>Imóveis avulsos publicados</TitleCard>
      <DivInfo>
        <Text>Em condomínio</Text>
        <Number>{emCondominio.length}</Number>
      </DivInfo>
      <DivInfo>
        <Text>Fora de condomínio</Text>
        <Number>{foraDeCondominio.length}</Number>
      </DivInfo>
      <LineCard />
      <DivInfo>
        <Text>Total</Text>
        <Number>{emCondominio.length + foraDeCondominio.length}</Number>
      </DivInfo>
    </Card>
  );
}

import {
  Card,
  DivInfo,
  LineCard,
  Number,
  StatusDiv,
  Text,
  TitleCard,
} from "./styles";

interface CardImovel002Props {
    titulo: string;
    countLancamentos?: number;
    countAvulsos?: number;
    show?: boolean;
}

export function CardImovel002({ 
    titulo, 
    countLancamentos=0, 
    countAvulsos=0,
    show=true,
}: CardImovel002Props) {

  if (!show) return <></>;

  return (
    <Card>
      <TitleCard>{ titulo }</TitleCard>
      <DivInfo>
        <StatusDiv>
          <Text>Lançamentos</Text>
        </StatusDiv>
        <Number>{ countLancamentos }</Number>
      </DivInfo>
      <DivInfo>
        <StatusDiv>
          <Text>Avulsos</Text>
        </StatusDiv>
        <Number>{ countAvulsos }</Number>
      </DivInfo>
      <LineCard />
      <DivInfo>
        <Text>Total</Text>
        <Number>{ countAvulsos + countLancamentos }</Number>
      </DivInfo>
    </Card>
  );
}

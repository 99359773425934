import { Constant, DATETIME_TYPE } from "../constants";
import { createDateTimeString } from "../helpers/datetime";
import { ChartType, FormatDateTimeOptions } from "../types/auxiliar.type";
import { ImovelType } from "../types/imoveis.type";

export const getImovelLink = (codigo: string, imovelType: ImovelType = ImovelType.IMOVEL, id: string = ''): string => {
    if (!codigo) return '';

    const tipo = imovelType === ImovelType.CONDOMINIO 
        ? Constant.CONDOMINIOS 
        : Constant.IMOVEIS;

    const param = imovelType === ImovelType.CONDOMINIO 
        ? id 
        : codigo;

    return `${process.env.REACT_APP_PAINEL_BASE_URL}${tipo}/abrir/${param}`;
}

export const transformToChartData = (data: any[], chave: string, tipo?: string): ChartType => {
    const dataChart: ChartType = {
      labels: [],
      values: []
    };

    const dataCount: any = {};

    data.forEach((item) => {
        if(item[chave]){
            const label = (tipo === DATETIME_TYPE)
                ? (new Date(item[chave].split(" ")[0])).toISOString().split('T')[0]
                : item[chave];
            
            if(!dataCount[label]) dataCount[label] = 0;
            dataCount[label]++;
        }
    })

    for (const label in dataCount) {
        if (dataCount.hasOwnProperty(label)) {
            dataChart.labels.push(label);
            dataChart.values.push(dataCount[label]);
        }
    }

    return dataChart;
}

export const formatDatetime = (datetime: string, options?: FormatDateTimeOptions): string => {

    if (!datetime) return '';

    const date = new Date(datetime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return createDateTimeString({day, month, year, hour, minute}, options);

};
import { styled } from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  grid-gap: 15px;
  @media(max-width: 690px) {
    grid-template-columns: 1fr;
  }
`;

import { ChartType } from "./auxiliar.type";

export type LeadsInfo = {
    leads: Array<Lead>;
    leadsCount: number;
    chartByDate: ChartType;
    chartByOrigin: ChartType;
    chartByBairro: ChartType;
    leadsCurrentMonth: Array<Lead>;
    leadsLastAccessCount: number;
    leadsCurrentMonthCount: number;
    rangeDate: {initialDate: string, lastDate: string};
};

export interface Lead {
    bairro: string;
    cidade: string;
    codigo: string;
    dataHora: string;
    estado: string;
    origem: string;
}

export enum LeadsQueryFields {
    DATA_HORA_DE = 'data_hora_de',
    DATA_DE = 'data_de',
    DATA_ATE = 'data_ate',
    DATA_HORA_DESC = 'ordenacao%5Bcampo%5D=dataHora&ordenacao%5Bvalor%5D=DESC',
}
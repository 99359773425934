import { Button, Container, Line, Titulo } from "./styles";

interface Props {
    titulo: string,
    url?: string,
    hideButton?: boolean;
}

export default function Header({ titulo, url="#", hideButton = false }: Props) {
    return (
        <Container>
            <Titulo data-testid='header-title'>{titulo}</Titulo>
            <Line />
            { !hideButton &&
                <Button href={url} target="_blank">Ver relatório completo</Button>
            }
        </Container>
    )
}

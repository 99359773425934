import { create } from "zustand";
import { LeadsInfo } from "../shared/types/leads.type";
import { ImoveisInfo } from "../shared/types/imoveis.type";
import { imobiliariaInitialValues, imoveisInitialValues, leadsInitialValues, userInitialValues } from "./initial-values";
import { getDataInfoDashboard } from "../services/api/dashboardService";
import { UserType } from "../shared/types/user.types";
import { ImobiliariaType } from "../shared/types/imobiliaria.types";

export type DashboardStore = {
  imoveisInfo: ImoveisInfo;
  leadsInfo: LeadsInfo;
  user: UserType;
  imobiliaria: ImobiliariaType;
  fetchDashboardInfo: (imobiliariaId: string, userId: string) => Promise<void>;
};

const dashboardStore = create<DashboardStore>()((set) => ({
  imoveisInfo: imoveisInitialValues,
  leadsInfo: leadsInitialValues,
  user: userInitialValues,
  imobiliaria: imobiliariaInitialValues,

  fetchDashboardInfo: async (imobiliariaId: string, userId: string) => {
    try {
      const response = (await getDataInfoDashboard(
        Number(imobiliariaId), Number(userId)
      )) as DashboardStore;
      set({
        leadsInfo: response?.leadsInfo || leadsInitialValues,
        imoveisInfo: response?.imoveisInfo || imoveisInitialValues,
        user: response?.user || userInitialValues,
        imobiliaria: response?.imobiliaria || imobiliariaInitialValues,
      });
    } catch (error) {
      console.error(error);
    }
  },
}));

export default dashboardStore;

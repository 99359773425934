import { ChartKeyType, ChartType } from "../types/auxiliar.type";

export const filterByDate = (
  data: any[],
  chave: string,
  dataInicio: Date,
  dataFim: Date
): any[] => {
  return data.filter((item) => {
    const dataItem = new Date(item[chave]);
    const mesAnoDataItem = dataItem.getFullYear() * 100 + dataItem.getMonth();

    const mesAnoInicio = dataInicio.getFullYear() * 100 + dataInicio.getMonth();
    const mesAnoFim = dataFim.getFullYear() * 100 + dataFim.getMonth();

    return mesAnoDataItem >= mesAnoInicio && mesAnoDataItem <= mesAnoFim;
  });
};

export const filterByOrigem = (data: any[], options: any[]): any[] => {
  return filterByExistInArray(data, "origem", options);
};

export const filterByExistInArray = (
  data: any[],
  chave: string,
  options: any[]
): any[] => {
  return data.filter((item) => options.includes(item[chave]));
};

const generateMonthSet = (
  dataInicio: Date,
  dataFim: Date
): { [label: string]: number } => {
  const monthSet: { [label: string]: number } = {};
  const dataInicial = new Date(
    dataInicio.getFullYear(),
    dataInicio.getMonth()
  );

  const dataFinal = new Date(dataFim.getFullYear(), dataFim.getMonth());

  while (dataInicial <= dataFinal) {
    const label = dataInicial.toISOString().slice(0, 7);
    monthSet[label] = 0;

    dataInicial.setMonth(dataInicial.getMonth() + 1);
  }

  return monthSet;
};

export const extractDataToChart = (
  data: Array<any>,
  key: string,
  type: ChartKeyType,
  dataInicio?: Date,
  dataFim?: Date
): ChartType => {
  const dataChart: ChartType = {
    labels: [],
    values: [],
  };

  const dataCount: { [label: string]: number } =
    dataInicio && dataFim ? generateMonthSet(dataInicio, dataFim) : {};

  data.forEach((item) => {
    if (item[key] !== undefined) {
      const label =
        type === ChartKeyType.DATETIME
          ? new Date(item[key]).toISOString().slice(0, 7)
          : item[key].toString();

      dataCount[label] = dataCount[label] + 1;
    }
  });

  for (const [label, count] of Object.entries(dataCount)) {
    dataChart.labels.push(label);
    dataChart.values.push(count);
  }

  return dataChart;
};

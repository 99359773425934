import { CardImovel003 } from "../../Imovel/CardImovel003";
import { useState } from "react";
import { Tabs } from "../../../shared/constants";
import { TabCard001 } from "../TabCard001";
import { TabDiv, TabGrid, TabLine } from "./styles";
import dashboardStore from "../../../store/dashboardStore";
import { CardImovel002 } from "../../Imovel/CardImovel002";

export default function ImovelTab() {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.VENDA)
  const { imoveisInfo } = dashboardStore();
  const { 
    aluguel, 
    temporada, 
    venda, 
    chartByAluguel, 
    chartByTemporada, 
    chartByVenda, 
    emCondominio, 
    foraDeCondominio,
    lancamentos,
  } = imoveisInfo;

  const getDataChartByTipoNegocio = () => {
    switch(activeTab){
      case Tabs.VENDA:
        return chartByVenda;
      case Tabs.ALUGUEL:
        return chartByAluguel;
      case Tabs.TEMPORADA:
        return chartByTemporada;
    }
  }

  const getImoveisByTipo = () => {
    switch(activeTab){
      case Tabs.VENDA:
        return venda;
      case Tabs.ALUGUEL:
        return aluguel;
      case Tabs.TEMPORADA:
        return temporada;
    }
  }

  const getEmCondominio = () => {
    const imoveis = getImoveisByTipo();
    const emCondominioByTipo = imoveis.filter((imovel) => {
      return emCondominio.some(imovelCondominio => imovelCondominio.id === imovel.id)
    });
    return emCondominioByTipo;
  }

  const getForaDeCondominio = () => {
    const imoveis = getImoveisByTipo();
    const foraDeCondominioByTipo = imoveis.filter((imovel) => {
      return foraDeCondominio.some(imovelSemCondominio => imovelSemCondominio.id === imovel.id)
    });
    return foraDeCondominioByTipo;
  }

  return (
    <div data-testid='imovelTabId'>
      <TabDiv>
        <p className={activeTab === Tabs.VENDA ? 'active' : ''} onClick={() => setActiveTab(Tabs.VENDA)}>Venda</p>
        <p className={activeTab === Tabs.ALUGUEL ? 'active' : ''} onClick={() => setActiveTab(Tabs.ALUGUEL)}>Aluguel</p>
        <p className={activeTab === Tabs.TEMPORADA ? 'active' : ''} onClick={() => setActiveTab(Tabs.TEMPORADA)}>Temporada</p>
        <TabLine />
      </TabDiv>
      <TabGrid venda={activeTab === Tabs.VENDA}>
        <TabCard001 dataChart={getDataChartByTipoNegocio()} />
        <CardImovel002 
            titulo="Imóveis publicados" 
            countLancamentos={lancamentos.length}
            countAvulsos={venda.length}
            show={activeTab === Tabs.VENDA}
        />
        <CardImovel003 emCondominio={getEmCondominio()} foraDeCondominio={getForaDeCondominio()}/>
      </TabGrid>
    </div>
  );
}

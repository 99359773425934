import { ChartType } from "./auxiliar.type";

export type ImoveisInfo = {
  imoveis: Array<Imovel>;
  imoveisPublicados: Array<Imovel>;
  imoveisDespublicados: Array<Imovel>;
  venda: Array<Imovel>;
  aluguel: Array<Imovel>;
  temporada: Array<Imovel>;
  lancamentos: Array<Imovel>;
  avulsosPublicados: Array<Imovel>;
  emCondominio: Array<Imovel>;
  foraDeCondominio: Array<Imovel>;
  maisVisualizados: Array<Imovel>;
  bairrosMaisVisualizados: Array<{nome: string, visualizacoes: number}>;
  condEmpreendimento: Array<Condominio>;
  condEmpreendimentoPublicados: Array<Condominio>;
  condEmpreendimentoDespublicados: Array<Condominio>;
  empreendimentos: {
    publicados: Array<Condominio>;
    despublicados: Array<Condominio>;
  };
  condominios: {
    publicados: Array<Condominio>;
    despublicados: Array<Condominio>;
  };
  chartByTipo: ChartType;
  chartByVenda: ChartType;
  chartByAluguel: ChartType;
  chartByTemporada: ChartType;
};

export interface Imovel extends ImovelBase {
  condEmpId: number;
}

export interface Condominio extends ImovelBase {
  nome: string;
  faseObra: string;
}

interface ImovelBase {
  id: number;
  codigo: string;
  imobiliaria: number;
  bairro?: string;
  visualizacoes?: number;
  endereco?: string;
  dataAtualizacao: string;
}

export enum ImovelType {
    IMOVEL = 'I',
    CONDOMINIO = 'C'
}

export const colorsByImovelType: Record<string, string> = {
    'Apartamento': '#1f77b4',
    'Casa': '#ff7f0e',
    'Casa de Vila': '#2ca02c',
    'Casa em Condomínio': '#d62728',
    'Cobertura': '#9467bd',
    'Flat': '#8c564b',
    'Kitnet/Conjugado': '#e377c2',
    'Loft': '#7f7f7f',
    'Sitio Residencial': '#bcbd22',
    'Andar': '#17becf',
    'Armazém': '#1f77b4',
    'Casa Comercial': '#ff7f0e',
    'Depósito': '#2ca02c',
    'Galpão': '#d62728',
    'Hotel': '#9467bd',
    'Loja': '#8c564b',
    'Outros': '#e377c2',
    'Ponto comercial': '#7f7f7f',
    'Pousada': '#bcbd22',
    'Prédio': '#17becf',
    'Sala Comercial': '#1f77b4',
    'Sobrado': '#ff7f0e',
    'Sobreloja': '#2ca02c',
    'Terreno Comercial': '#d62728',
    'Vaga de Garagem': '#9467bd',
    'Chácara': '#8c564b',
    'Fazenda': '#e377c2',
    'Haras': '#7f7f7f',
    'Rancho': '#bcbd22',
    'Sítio': '#17becf',
    'Industrial': '#1f77b4',
    'Studio': '#ff7f0e',
    'Terreno': '#2ca02c',
};

export const colorsByOrigin: Record<string, string> = {
    'Cadastro de Interesse': '#1f77b4',
    'Indicação': '#ff7f0e',
    'Contato': '#2ca02c',
    'Avaliação': '#d62728',
    'Ligamos para Você': '#9467bd',
    'Mais Informações': '#8c564b',
    'Agende uma Visita': '#e377c2',
    'Outras Informações': '#7f7f7f',
    'Reclamações': '#bcbd22',
    'Curriculos': '#17becf',
    'Favoritos': '#1f77b4',
    'Atendimento Online': '#ff7f0e',
    'Fale Conosco': '#2ca02c',
    'Popup de Saida do Site': '#d62728',
    'Calculadora de Avaliação': '#9467bd',
    'Landing Page': '#8c564b',
};

export const getCustomColors = (labels: Array<string>, colors: Record<string, string>) => {
    const result = labels.map(label => {
        const color = colors[label];
        return color !== undefined ? color : getRandomColor();
    });

    return result;
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
import { ImovelType } from "../../../shared/types/imoveis.type";
import { formatDatetime } from "../../../shared/utils/utils";
import dashboardStore from "../../../store/dashboardStore";
import { EmptyData } from "../../EmptyData";
import CardTopItem from "../CardTopItem";
import { Card, Table, TitleCard } from "./styles";

export function CardTop004() {
    const { condEmpreendimentoPublicados: lancamentos } = dashboardStore().imoveisInfo;

    const maxImoveis = 10;
    const imoveis = lancamentos.slice(0, maxImoveis);

    return (
        <Card>
            <TitleCard>Últimos Condomínios/Lançamentos atualizados</TitleCard>
            {
                imoveis?.length ? 
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>CÓDIGO</th>
                                <th>NOME</th>
                                <th>ATUALIZADO</th>
								<th>LINK</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                imoveis?.map((lancamento) => {
                                    const atualizado = formatDatetime(
                                        lancamento.dataAtualizacao, {includeTime: false}
                                    );
                                    return (
                                        <CardTopItem
                                            code={lancamento.codigo}
                                            value={lancamento.nome}
                                            atualizado={atualizado}
                                            tipo={ImovelType.CONDOMINIO}
                                            id={lancamento.id}
                                            key={lancamento.id}
                                        />
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </> : <EmptyData />
            }
        </Card>
    );
}
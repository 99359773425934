import { Card, TitleCard } from "./styles";
import { ChartPie } from "../../Charts/ChartPie";
import { ChartType } from "../../../shared/types/auxiliar.type";
import { colorsByImovelType } from "../../Charts/ChartPie/customColors";

interface TapCard001Props {
    dataChart: ChartType;
}

export function TabCard001({ dataChart }: TapCard001Props) {

  return (
    <Card data-testid='tabcard001'>
      <TitleCard>Imóveis por tipo</TitleCard>
        <ChartPie
            labels={dataChart.labels}
            values={dataChart.values}
            colors={colorsByImovelType}
            height={330}
        />
    </Card>
  );
}

import { styled } from "styled-components";

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
    padding: 20px;
    position: relative;
    grid-column: 1/2;
    grid-row:2/3;
    @media (max-width: 1120px) {
        grid-column: auto;
        grid-row: auto;
    }
`;

export const Text = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #484444;
`;

export const Number = styled.h1`
    margin: 0;
    font-size: 28px;
`;

export const View = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const Button = styled.a`
    all: unset;
    font-size: 16px;
    color: orange;
    margin-left: 15px;
    width: auto;
    font-weight: bold;
    text-wrap: nowrap;
    cursor: pointer;
`;
import { Column, ColumnTop, Container, DivImovel, DivLeads, DivTemp, DivTop, FirstColumn, ResponsiveDiv, SecondColumn, Section, ThirdColumn, FullWidth } from "./styles";
import Skeleton from 'react-loading-skeleton'

export function LoadingPage() {

  return (
    <Container>
      <Section>
        <Skeleton height={15} width={FullWidth}/>
        <DivLeads>
            <FirstColumn>
                <div style={{width: FullWidth, height:FullWidth}}>
                <Skeleton height={191} width={FullWidth} containerClassName="flex-1"/>
                </div>
                <div style={{width: FullWidth, height:FullWidth}}>
                <Skeleton height={191} width={FullWidth} containerClassName="flex-1"/>
                </div>
            </FirstColumn>
            <ResponsiveDiv>
                <SecondColumn>
                    <Skeleton height={398} width={FullWidth} containerClassName="flex-1"/>
                </SecondColumn>
                <ThirdColumn>
                    <Skeleton height={398} width={FullWidth} containerClassName="flex-1"/>
                </ThirdColumn>
            </ResponsiveDiv>
        </DivLeads>
      </Section>
      <Section>
        <Skeleton height={15} width={FullWidth} />
        <DivImovel>
            <Column>
                <Skeleton height={213} width={FullWidth} containerClassName="flex-1"/>
            </Column>
            <Column>
                <Skeleton height={213} width={FullWidth} containerClassName="flex-1"/>
            </Column>
            <Column>
                <Skeleton height={213} width={FullWidth} containerClassName="flex-1"/>
            </Column>
        </DivImovel>
        <Skeleton height={15} width={FullWidth} />
        <DivTemp>
            <Column style={{width: '120%'}}>
                <Skeleton height={350} width={FullWidth} containerClassName="flex-1"/>
            </Column>
            <Column>
                <Skeleton height={350} width={FullWidth} containerClassName="flex-1"/>
            </Column>
            <Column>
                <Skeleton height={350} width={FullWidth} containerClassName="flex-1"/>
            </Column>
        </DivTemp>
        <DivTop>
            <ColumnTop>
                <Skeleton height={541} width={FullWidth} containerClassName="flex-1"/>
            </ColumnTop>
            <ColumnTop>
                <Skeleton height={541} width={FullWidth} containerClassName="flex-1"/>
            </ColumnTop>
        </DivTop>
        <DivTop>
            <ColumnTop>
                <Skeleton height={541} width={FullWidth} containerClassName="flex-1"/>
            </ColumnTop>
            <ColumnTop>
                <Skeleton height={541} width={FullWidth} containerClassName="flex-1"/>
            </ColumnTop>
        </DivTop>
      </Section>
    </Container>
  );
}

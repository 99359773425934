import dashboardStore from "../../../store/dashboardStore";
import { CardImovel001 } from "../CardImovel001";
import { Container } from "./styles";

export default function ImoveisGrid() {
  const { 
    imoveisPublicados, 
    imoveisDespublicados,
    empreendimentos,
    condominios,
  } = dashboardStore().imoveisInfo;

  return (
    <Container data-testid='imoveisGridId'>
      <CardImovel001 
        titulo="Imóveis" 
        subTitulo="(venda, aluguel e temporada)"
        publicados={imoveisPublicados.length} 
        despublicados={imoveisDespublicados.length}
      />
      <CardImovel001
        titulo={"Empreendimentos"} 
        publicados={empreendimentos.publicados.length}
        despublicados={empreendimentos.despublicados.length}
      />
      <CardImovel001 
        titulo={"Condomínios"} 
        publicados={condominios.publicados.length}
        despublicados={condominios.despublicados.length}
      />
    </Container>
  );
}

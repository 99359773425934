import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { getCustomColors } from "./customColors";
import { EmptyData } from "../../EmptyData";

export interface ChartPieProps {
    labels: Array<string>;
    values: Array<any>;
    colors?: Record<string, string>;
    width?: string | number;
    height?: string| number;
}

export function ChartPie({ labels, values, colors, width, height }: ChartPieProps) {  
  const options: ApexOptions = {
    labels,
    ...(colors && { colors: getCustomColors(labels, colors) }),
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
  };
  const series = values;

  if (!series.length) return <EmptyData />;

  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      width={width}
      height={height}
    />
  );
}

import { styled } from "styled-components";

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 8px;
`;

export const TitleCard = styled.h2`
    margin: 0;
    font-size: 20px;
`;

export const DivInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Text = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #484444;
`;

export const Number = styled.h1`
    margin: 0;
    font-size: 28px;
`;

export const LineCard = styled.div`
    height: 0.5px;
    width: 100%;
    background-color: gray;
`;
import { styled } from "styled-components";

interface Props{
  venda: boolean;
}

export const TabDiv = styled.div`
  display: flex;
  position: relative;
  padding: 0px 15px;
  margin-top: 35px;
  p {
    margin: 0px;
    width: 90px;
    padding: 5px 5px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    background-color: white;
    color: #23476c;
    border: 1px solid #23476c;
    border-bottom-color: white;
    z-index: 15;
    border-radius: 5px 5px 0px 0px;
  }
`;

export const TabLine = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: #23476c;
  margin-top: 5px;
  position: absolute;
  bottom: 1px;
`;

export const TabGrid = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({venda}) => venda ? '1.3fr 1fr 1fr' : '1fr 1fr' }; 
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  grid-gap: 15px;
  transition: all 0.7s;
  @media(max-width: 690px) {
    grid-template-columns: 1fr;
  }
`;

import { Dashboard } from './Pages/Dashboard';
import { SkeletonTheme } from 'react-loading-skeleton';
import  './styles/global.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { Theme } from './shared/Theme';

function App() {
  return (  
    <SkeletonTheme baseColor={Theme.colors.skeletonBase} highlightColor={Theme.colors.skeletonHighlight}>
      <Dashboard/>   
    </SkeletonTheme>   
  );
}

export default App;

import { externalRoutes } from "../../../shared/externalRoutes";
import { LeadsQueryFields } from "../../../shared/types/leads.type";
import { formatDatetime } from "../../../shared/utils/utils";
import dashboardStore from "../../../store/dashboardStore";
import { Button, Card, Number, Text, View } from "./styles";

interface Props {
    count?: number;
}

export function Card002({ count }: Props){

    const { lastAccess } = dashboardStore().user;

    const lastAccessFormat = formatDatetime(lastAccess);

    const queryString = `${LeadsQueryFields.DATA_DE}=${lastAccessFormat}&${LeadsQueryFields.DATA_HORA_DESC}`;
    const lastLeadsUrlAccess = `${externalRoutes.leads}?${queryString}`;

    return(
        <Card>
            <Text>
                Leads novos desde seu último acesso em: <small>{ lastAccessFormat }</small> 
            </Text>
            <View>
                <Number>{ count || 0 }</Number>
                <Button href={lastLeadsUrlAccess} target="_blank">Lista detalhada</Button> 
            </View>
        </Card>
    );
}
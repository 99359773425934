import { Button, Card, DivInfo, Text, TitleCard } from "./styles";
import { ChartKeyType, ChartType } from "../../../shared/types/auxiliar.type";
import { ChartArea } from "../../Charts/ChartArea";
import { useEffect, useState } from "react";
import { extractDataToChart, filterByDate, filterByOrigem } from "../../../shared/utils/chartFilters";
import { Lead } from "../../../shared/types/leads.type";
import { EmptyData } from "../../EmptyData";

interface Props {
	chartData: ChartType;
	leads: Array<Lead>;
}

export function Card003({ chartData, leads }: Props) {
	const [labels, setLabels] = useState<string[]>(chartData?.labels);
	const [values, setValues] = useState<number[]>(chartData?.values);

	const defaultFilterMonth = 6;

	const dataFim = new Date();
	const dataInicio = new Date(dataFim);
	dataInicio.setMonth(dataFim.getMonth() - defaultFilterMonth);

	const filter = (dataInicio: Date, dataFim: Date, origens: string[] = []) => {
		let leadsFiltradas = leads;
		leadsFiltradas = filterByDate(leadsFiltradas, 'dataHora', dataInicio, dataFim);

		/** TODO: ainda falta implementar os filtros */
		/* leadsFiltradas = filterByOrigem(leadsFiltradas, []); */

		const newData = extractDataToChart(
			leadsFiltradas, 'dataHora', ChartKeyType.DATETIME, dataInicio, dataFim
		);

		setLabels(newData.labels);
		setValues(newData.values);
	}

	useEffect(() => {
		filter(dataInicio, dataFim);
	}, []);

	return (
		<Card data-testid='card003' style={{ gridColumn: "2/3", gridRow: "1/3" }}>
			<TitleCard>Grafico de leads</TitleCard>
            <DivInfo>
                <Text>Últimos 6 meses:</Text>
                {/* <Button>+ Filtros</Button> */}
            </DivInfo>
            <ChartArea
                labels={labels}
                values={values}
                tooltip
                height={160}
            />
		</Card>
	);
}

import { FaRegEye } from "react-icons/fa";
import { Constant } from "../../../shared/constants";
import { getImovelLink } from "../../../shared/utils/utils";
import { ImovelType } from "../../../shared/types/imoveis.type";

interface Props {
    code: string;
    value?: string;
    tipo: ImovelType;
    id?: number;
    atualizado?: string;
}

export default function CardTopItem({ 
    code, 
    value,  
    tipo, 
    id,
    atualizado='', 
}: Props) {
  return (
    <tr>
      <td className="code">{ code }</td>
      <td>{ value?.trim() || Constant.UNNAMED }</td>
      <td>{ atualizado }</td>
      <td className="link">
        <a href={getImovelLink(code, tipo, id?.toString())} target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
          <FaRegEye size={26} color="#4c6481"/>
        </a>
      </td>
    </tr>
  );
}

import dashboardStore from "../../../store/dashboardStore";
import { Card001 } from "../Card001";
import { Card002 } from "../Card002";
import { Card003 } from "../Card003";
import { Card004 } from "../Card004";
import { Container } from "./styles";

export default function LeadsGrid() {
  const { 
	leadsCurrentMonthCount,
    leadsLastAccessCount, 
	leadsCount, 
	chartByDate, 
	chartByOrigin,
    leads,
  } = dashboardStore().leadsInfo;

  return (
    <Container>
      <Card001 count={leadsCount} currentMonthCount={leadsCurrentMonthCount} />
      <Card002 count={leadsLastAccessCount} />
      <Card003 chartData={chartByDate} leads={leads} />
      <Card004 chartData={chartByOrigin}/>
    </Container>
  );
}

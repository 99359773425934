export type ChartType = {
  labels: Array<string>;
  values: Array<number>;
};

export enum ChartKeyType {
    DATETIME = 'datetime',
    DATE = 'date',
};

export interface FormatDateTimeOptions {
    includeDate?: boolean;
    includeTime?: boolean;
};

export interface DateTimeObj {
    day?: string;
    month?: string;
    year?: number;
    hour?: string;
    minute?: string;
}

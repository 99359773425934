import { Card, TitleCard } from "./styles";
import dashboardStore from "../../../store/dashboardStore";
import { ChartBar } from "../../Charts/ChartBar";

export function CardTop002() {
  const { bairrosMaisVisualizados } = dashboardStore().imoveisInfo;

  const bairros = bairrosMaisVisualizados.filter(bairro => bairro.visualizacoes > 0);

  const labels = bairros.map(bairro => bairro.nome);
  const values = bairros.map(bairro => bairro.visualizacoes);

  return (
    <Card>
      <TitleCard>Bairros (visualizações de imóveis por bairro)</TitleCard>
        <ChartBar
            labels={labels}
            values={values}
            height={"90%"}
            width={"100%"}
            name="Visualizações"
            tooltip
        />
    </Card>
  );
}

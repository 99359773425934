
interface EmptyDataProps {
    text?: string;
}

export const EmptyData = ({ text }: EmptyDataProps) => {

  const displayText = text ?? 'Não há dados para serem exibidos!';

  /* TODO: Criar componente EmptyData para ser exibido quando nao houver dados para exibir */
  return (
    <div>{ displayText }</div>
  )
}
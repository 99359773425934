import { apiRoutes } from '../../shared/apiRoutes';
import { get } from '../httpClient';

export const getDataInfoDashboard = async (imobiliaria_id: number, user_id: number) => {
    try {
        const response = await get(apiRoutes.dashboard.info, { imobiliaria_id, user_id });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

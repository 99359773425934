import { styled } from "styled-components";

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
`;

export const TitleCard = styled.h2`
    margin: 0;
    font-size: 20px;
    margin-bottom: 15px;
`;
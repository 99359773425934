import { styled } from "styled-components";

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 15px;
    height: max-content;
`;

export const TitleCard = styled.h2`
    margin: 0;
    font-size: 20px;
`;

export const Table = styled.table`
border-collapse: collapse;

th{
    font-weight: bold;
    color: #909090;
    font-size: 16px;
    text-align: start;
    padding: 10px 5px;
}

td{
    padding: 20px 5px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid gray;
}

td:first-child {
    white-space: nowrap;
}

tr{
    .code{
        color: #F49431;
    }
    .view{
        padding: 0px 45px;
    }
    .link{
        color: #F49431;
        padding: 20px 9px;
    }
}
`;
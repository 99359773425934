import { formatDatetime } from "../../../shared/utils/utils";
import dashboardStore from "../../../store/dashboardStore";
import { Card, DivInfo, LineCard, Number, Text, TitleCard } from "./styles";

interface Props {
    currentMonthCount?: number;
    count?: number;
}

export function Card001({ count, currentMonthCount }: Props){
    const { rangeDate } = dashboardStore().leadsInfo;
    const initialDate = formatDatetime(rangeDate.initialDate, { includeTime: false });

    return(
        <Card>
            <TitleCard>Quantidade de Leads</TitleCard>
            <DivInfo>
                <Text>Este mês:</Text>
                <Number>{ currentMonthCount || 0 }</Number>
            </DivInfo>
            <LineCard />
            <DivInfo>
                <Text>Total desde { initialDate }</Text>
                <Number>{ count || 0 }</Number>
            </DivInfo>    
        </Card>
    );
}
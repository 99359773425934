import { useEffect, useState } from 'react';
import { MD5 } from 'crypto-js';

export const useSearchParams = (hash: string | null) => {
  const [invalid, setInvalid] = useState(false);
  const [imobiliariaId, setImobiliariaId] = useState('');
  const [userId, setUserId] = useState('');
  const [waitHash, setWaitHash] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const id = searchParams.get('imobiliaria_id') || '';
    const usuario = searchParams.get('usuario') || '';

    if (!id || !usuario) {
      setInvalid(true);
      return;
    }

    const hashKey = process.env.REACT_APP_HASH_KEY;
    const hashString = id + usuario + hashKey;

    const hashCheck = MD5(hashString).toString();

    if (!hash) {
        setWaitHash(true);
        return;
    }

    if (hashCheck !== hash) {
      setInvalid(true);
      setWaitHash(false);
      return;
    }

    setImobiliariaId(id);
    setUserId(usuario);
    setWaitHash(false);
  }, [hash]);

  return { invalid, waitHash, imobiliariaId, userId };
};

import { styled } from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: hidden;
    min-height: 100vh;
`;

export const Section = styled.div`
`;

export const DivLeads = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 390px;
    @media (max-width: 1120px) {
        flex-direction: column;
        height: 100%;
    }
`;

export const ResponsiveDiv = styled.div`
 @media (max-width: 1120px) {
    display: flex;
    flex-direction: row;
    gap: 14px
 }

 @media (max-width: 690px) {
    display: flex;
    flex-direction: column;
    gap: 14px
 }
`;

export const DivImovel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 243px;
`;

export const DivTemp = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 380px;
`;

export const DivTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 571px;
`;

export const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 14px;
    @media (max-width: 1120px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media (max-width: 690px) {
        flex-direction: column;
    }
`;

export const SecondColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 190%;
    height: 100%;
    @media (max-width: 1120px) {
        width: 100%;
    }
`;

export const ThirdColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
`;

export const ColumnTop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
`;

export const FullWidth = '100%';
import { styled } from "styled-components";

export const TitleCard = styled.h2`
    margin: 0;
    font-size: 20px;
`;

export const Text = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #484444;
`;

export const Button = styled.button`
    all: unset;
    font-size: 16px;
    color: orange;
    margin-left: 15px;
    width: auto;
    font-weight: bold;
    text-wrap: nowrap;
    cursor: pointer;
`;

export const DivInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    padding: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
import { useEffect, useState } from 'react';

export const messageTypes = {
   HASH_VALUE: 'hashValue',
}

export const useIframeGetMessage = () => {
  const [hashValue, setHashValue] = useState(null);

  useEffect(() => {
    const receiveMessage = (event: { data: { type?: string, value?: any}; }) => {
      if (event.data) {
        const data = event.data;
        if (data?.type === messageTypes.HASH_VALUE) {
          setHashValue(data?.value);
        }
      }
    };

    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return { hashValue };
};

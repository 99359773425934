import { styled } from "styled-components";


export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Section = styled.div`
`;

export const Card = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
`;

export const TitleCard = styled.h2`
    margin: 0;
    font-size: 20px;
`;

export const DivInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Text = styled.p`
    margin: 0;
    font-size: 18px;
`;

export const Number = styled.h1`
    margin: 0;
    font-size: 24px;
`;

export const LineCard = styled.div`
    height: 0.5px;
    width: 100%;
    background-color: gray;
`;

export const DynamicCard = styled.div`
    border-radius: 7px;
    border: 1px solid #23476C;
    min-height: 300px;
    display: grid;
    width: auto;
    margin: 15px;
`;

export const TopGrid = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-auto-rows: max-content;
flex-direction: row;
justify-content: space-between;
padding: 15px;
grid-gap: 15px;

@media(max-width: 900px){
    grid-template-columns: 1fr;
}
`;

export const ViewGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
padding: 15px;
grid-gap: 15px;
@media (max-width: 690px) {
        grid-template-columns: 1fr;
}
`;
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { EmptyData } from "../../EmptyData";

export interface ChartAreaProps {
  labels: Array<string>;
  values: Array<any>;
  width?: string | number;
  height?: string | number;
  tooltip?: boolean;
}

export function ChartArea({
  labels,
  values,
  width,
  height,
  tooltip = false,
}: ChartAreaProps) {
  const options: ApexOptions = {
    colors: ["#F49431"],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "#8B929E",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: tooltip,
      x: {
        format: 'MMM/yy'
    }
      
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        color: "#8B929E",
      },
      axisTicks: {
        color: "#8B929E",
      },

      categories: labels,
    },
    fill: {
      opacity: 1,
      colors: ["#F49431"],
      gradient: {
        shade: "dark",
        opacityFrom: 0.7,
        opacityTo: 0.6,
      },
    },
  };
  const series = [
    {
      name: "Número de Leads",
      data: values,
    },
  ];

  if (!values.length) return <EmptyData />

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width={width}
      height={height}
    />
  );
}

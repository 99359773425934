import { FaRegEye } from "react-icons/fa";
import { Constant } from "../../../../shared/constants";
import { getImovelLink } from "../../../../shared/utils/utils";

interface Props {
  code: string;
  name?: string;
  views?: number;
}

export default function CardTopItem001({ code, name, views }: Props) {
  return (
    <tr>
      <td className="code">{code}</td>
      <td>{name || Constant.UNNAMED}</td>
      <td className="view">{views || 0}</td>
      <td className="link">
        <a href={getImovelLink(code)} target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
          <FaRegEye size={26} color="#4c6481"/>
        </a>
      </td>
    </tr>
  );
}

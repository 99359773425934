import { DateTimeObj, FormatDateTimeOptions } from "../types/auxiliar.type";

export const createDateTimeString = (
    { day, month, year, hour, minute }: DateTimeObj,
    options: FormatDateTimeOptions = {}
  ): string => {
    const { includeDate = true, includeTime = true } = options;
  
    const formattedDate = includeDate ? `${day}/${month}/${year}` : '';
    const formattedTime = includeTime ? `${hour}:${minute}` : '';
  
    return `${formattedDate}${includeDate && includeTime ? ' ' : ''}${formattedTime}`;
  };
import dashboardStore from "../../../store/dashboardStore";
import {
  Card,
  DivInfo,
  LineCard,
  Number,
  Status,
  StatusDiv,
  Text,
  TitleCard,
} from "./styles";

interface CardImovel001Props {
  titulo: string;
  subTitulo?: string;
  publicados?: number;
  despublicados?: number;
}

export function CardImovel001({
  titulo,
  subTitulo,
  publicados = 0,
  despublicados = 0
}: CardImovel001Props) {

  const { isMidasWeb } = dashboardStore().imobiliaria;

  return (
    <Card>
      <TitleCard>{titulo} <small>{subTitulo}</small></TitleCard>
      <DivInfo>
        <StatusDiv>
          <Status color="#34AE40" />
          <Text>Publicados</Text>
        </StatusDiv>
        <Number>{publicados}</Number>
      </DivInfo>
      {!isMidasWeb && (
        <>
          <DivInfo>
            <StatusDiv>
              <Status color="#DD2121" />
              <Text>Despublicados</Text>
            </StatusDiv>
            <Number>{despublicados}</Number>
          </DivInfo>
          <LineCard />
          <DivInfo>
            <Text>Total</Text>
            <Number>
              {publicados + despublicados}
            </Number>
          </DivInfo>
        </>
      )}
    </Card>
  );
}

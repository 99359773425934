import dashboardStore from "../../../store/dashboardStore";
import { EmptyData } from "../../EmptyData";
import CardTopItem001 from "./CardTopItem001";
import { Card, Table, TitleCard } from "./styles";

export function CardTop001(){
    const { maisVisualizados } = dashboardStore().imoveisInfo;

    const maxImoveis = 10;
    const imoveis = maisVisualizados.slice(0, maxImoveis);

    return(
        <Card>
            <TitleCard>Imóveis</TitleCard>
            { 
                imoveis?.length ? 
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>IMÓVEL</th>
                                <th>BAIRRO</th>
                                <th>VISUALIZAÇÕES</th>
                                <th>LINK</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                imoveis?.map((imovel) => {
                                    return (
                                        <CardTopItem001 
                                            code={imovel.codigo} 
                                            name={imovel?.bairro} 
                                            views={imovel?.visualizacoes}
                                            key={imovel.id}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </> : <EmptyData /> 
            }   
    </Card>      
    );
}
import { Card, TitleCard } from "./styles";
import { ChartType } from "../../../shared/types/auxiliar.type";
import { ChartPie } from "../../Charts/ChartPie";
import { colorsByOrigin } from "../../Charts/ChartPie/customColors";
import dashboardStore from "../../../store/dashboardStore";
import { formatDatetime } from "../../../shared/utils/utils";

interface Props {
	chartData: ChartType;
}

export function Card004({ chartData }: Props) {
  const { rangeDate } = dashboardStore().leadsInfo;
  const initialDate = formatDatetime(rangeDate.initialDate, { includeTime: false });
  const title = initialDate ? `Desde ${initialDate}` : ``;

	return (
		<Card data-testid='card004' style={{ gridColumn: "3/4", gridRow: "1/3" }}>
			<TitleCard>Formulário de Origem</TitleCard>
            <h4>{ title }</h4>
            <ChartPie
                labels={chartData.labels}
                values={chartData.values}
                width={"100%"}
                height={"88%"}
                colors={colorsByOrigin}

            />
		</Card>
	);
}
